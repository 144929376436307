// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap Select
@import '~bootstrap-select/sass/bootstrap-select.scss';

// Telephone prefix
@import '~intl-tel-input/src/css/intlTelInput.scss';

// Bootstrap Calender
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.css';

//Slick Slider
@import '~slick-slider/slick/slick.scss';
@import '~slick-slider/slick/slick-theme.scss';

//JQuery Fancybox
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';

//JQuery Confirm
@import '~jquery-confirm/dist/jquery-confirm.min.css';

body {
    font-weight: 200;

    #mobileMenu {
        transform: translateX(-100%);
        transition: 0.5s cubic-bezier(0.26, 0.74, 0.22, 0.99);
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $white;
        z-index: 99;
        opacity: 0;
    }

    &.menu-open {
        overflow: hidden;

        #mobileMenu {
            transform: translateX(0);
            transition: 0.5s cubic-bezier(0.26, 0.74, 0.22, 0.99);
            opacity: 1;
        }

        #mobileHead {
            position: relative;
            z-index: 98;
            background-color: $white;
            border-bottom: 1px solid $gray-200;

            .block {
                margin: 0;
                padding: 0;
            }
        }

        #mobileContainer {
            position: fixed;
            top: 90px;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: scroll;
            z-index: 97;
            height: calc(100% - 90px);
        }

        .toggle-menu {
            svg {
                top: 0px;
            }
        }
    }
}

a {
    transition: 0.2s;
}

// h1,
// h2,
// h3,
// .btn {
//     font-family: $font-family-sans-serif;
// }
h3{
    font-size: 20px;
}

strong {
    font-weight: 700;
}

img {
    width: 100%;
}

.iti {
    width: 100%;
}

.gray-bg {
    background-color: $gray-200;
}

.vh-center {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-content: center;
    z-index: 1;
    align-items: center;

    svg {
        width: 100px;
        color: $gray-200;
        font-size: 100px;
        margin: 0 auto;
    }
}

.slider {
    .slider-item {
        position: relative;
        float: left;
    }

    .slick-slide {
        .slide-description {
            position: absolute;
            top: 40%;
            z-index: 1;
            width: 450px;
            background-color: rgba(0, 0, 0, 0.7);
            padding: 30px;
            color: $white;

            @include media-breakpoint-down(sm) {
                width: 70%;
                padding: 10px;
            }

            h3 {
                font-size: 30px;

                @include media-breakpoint-down(sm) {
                    font-size: 17px;
                }
            }

            p {
                font-size: 13px;
                font-weight: 200;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        &:nth-child(odd) {
            .slide-description {
                left: 45px;
                border-left: 5px solid $primary;

                @include media-breakpoint-down(sm) {
                    left: 10px;
                }
            }
        }

        &:nth-child(even) {
            .slide-description {
                right: 45px;
                border-right: 5px solid $primary;
                text-align: right;

                @include media-breakpoint-down(sm) {
                    right: 10px;
                }
            }
        }
    }
}

.slick-slide {
    display: none;

    img {
        width: 100%;
    }
}

.slick-prev,
.slick-next {
    display: none !important;

    &:before {
        color: $black;
    }

    @include media-breakpoint-down(sm) {
        display: none !important;
    }
}

.gallery-items {
    margin: 0 -5px;
    padding: 0;
    list-style: none;

    .gallery-item {
        float: left;
        width: 25%;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        a {
            display: block;
            position: relative;
            padding: 5px;
            overflow: hidden;

            img {
                max-width: 100%;
            }

            .gallery-inside {
                position: absolute;
                top: 5px;
                bottom: 5px;
                left: 5px;
                right: 5px;
                display: flex;
                align-items: center;
                transition: 0.2s;
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 0;

                .inside-content {
                    display: table;
                    margin: 0 auto;
                    color: $white;
                    padding: 10px;

                    h3 {
                        font-weight: 400;
                        font-size: 16px;
                    }
                }
            }

            &:hover {
                .gallery-inside {
                    opacity: 1;
                    transition: 0.2s;
                }
            }
        }
    }

}

.gallery-filter {
    padding-bottom: 10px;
    margin: 0 -3px;

    .btn {
        background-color: $gray-300;
        display: inline-block;
        margin: 3px;
        box-shadow: none;
        font-weight: 600;
        border-radius: 0;

        &.btn-primary {
            background-color: darken($primary, $amount: 20%);
            border-color: darken($primary, $amount: 20%);
        }
    }
}

.product-item {
    .image-slider {
        border: 1px solid $gray-200;
        background: $white;

        .item-main-image {
            height: 415px;
            overflow: hidden;
            display: flex;
            align-items: center;

            .main-image {
                display: table;
                margin: 0 auto;

                a {
                    img {
                        max-width: 100%;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }

    .image-nav {
        margin: 10px 25px 0 28px;

        .slick-slide {
            margin: 3px;
            display: flex;
            align-items: center;
            border: 1px solid $gray-500;
            height: 64px;
            &:hover{
                cursor:pointer;
                border: 1px solid $primary;
            }
            img {
                padding: 3px;
            }
            &.slick-current {
                border: 1px solid $primary;
            }
        }
    }

    .slick-prev,
    .slick-next {
        height: 28px;

        &:before {
            display: none;
        }

        svg {
            color: $black;
            font-size: 30px;
        }

        &.slick-disabled {
            svg {
                color: $gray-500;
            }
        }
    }
}

.list-group{
    .list-group-item{
        &.active{
            a{
                color: $white;
            }
        }
    }
}

.block {
    margin: 15px;
    padding: 15px;

    @include media-breakpoint-down(sm) {
        margin: 5px;
        padding: 5px;
    }

    &.system-message {
        position: relative;
        padding-right: 20px;
        button {
            padding: 0px;
        }
    }

    &.offline,
    &.site-form {
        margin: 0 auto;
        padding: 30px;
        border-radius: 5px;
        background-color: $white;
        border: 1px solid $gray-200;

        &.small-size {
            max-width: 400px;
        }

        @include media-breakpoint-down(sm) {
            padding: 15px;
        }

        legend {
            padding-bottom: 20px;
            font-size: 20px;
            text-align: center;
        }

        .form-control,
        .custom-select {
            min-height: 50px;
            font-size: 17px;
            padding-left: 20px;
            padding-right: 20px;

            &:focus {
                box-shadow: none;
                border-color: $primary;
            }
        }

        .iti--separate-dial-code {
            .iti__selected-flag {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }

        .input-group {

            .form-control,
            .custom-select {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }

            .iti--allow-dropdown {

                .form-control,
                .custom-select {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
        }


        .input-group-text {
            padding: 0.375rem 1.1rem 0.375rem 1.2rem;

            .fa {
                font-size: 25px;
            }
        }

        .bootstrap-select {
            width: 100% !important;

            .dropdown-toggle {
                font-size: 17px;
                line-height: 36px;
                border-radius: 4px;
                background: none;
                font-weight: 300 !important;
                box-shadow: none;
                border: 1px solid #ced4da;
                padding: 0.375rem 1.1rem 0.375rem 1.2rem;
            }

            .dropdown-menu {
                border-radius: 4px;
            }
        }
    }

    &.logo {
        margin-top: 0px;
        padding-top: 0px;

        .logo-description {
            float: left;
        }

        h1 {
            font-size: 25px;
            margin-bottom: 0px;
            color: $black;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                font-size: 18px;
            }
        }

        p {
            margin-bottom: 0px;
            font-size: 14px;
            color: $gray-600;
            letter-spacing: 0.7px;

            @include media-breakpoint-down(sm) {
                font-size: 10px;
            }
        }

        a {
            display: block;
            padding: 10px 0;

            &:hover {
                text-decoration: none;

                h1 {
                    color: $primary;
                }
            }
        }

        img {
            &.logo {
                max-width: 410px;
                transition: 0.2s;
                float: left;
                max-height: 70px;
                width: auto;
                margin-right: 10px;

                &.login {
                    float: none;
                }

                @include media-breakpoint-down(sm) {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    max-width: 70px;
                }
            }
        }
    }

    &.user-menu {
        margin-top: 0px;
        padding-top: 0px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            float: right;

            li {
                float: left;
                margin: 10px 0;

                &.my-profile {
                    span {
                        &+span {
                            font-size: 10px;
                            display: block;
                            color: $gray-600;
                            padding-left: 20px;
                            text-transform: uppercase;
                            font-weight: 300;
                            display: block;
                        }
                    }
                }

                a {
                    color: $gray-600;
                    font-size: 15px;
                    line-height: 21px;
                    display: block;
                    text-decoration: none;
                    border-right: 1px solid $gray-200;
                    padding: 0px 8px;

                    svg {
                        font-size: 15px;
                        color: $gray-500;
                        margin-right: 5px;
                    }

                    &:hover {
                        color: $primary;
                    }
                }

                &:last-of-type {
                    a {
                        border: none;
                        padding-right: 0px;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &.main-menu {
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            @include media-breakpoint-down(sm) {
                display: none;
            }
            li{
                position: relative;
                // float: left;
                flex: auto;
                a {
                    color: $black;
                    transition: 0.1s;
                    font-size: 16px;
                    text-decoration: none;
                    display: block;
                    text-align: left;
                    font-weight: 200;
                    padding: 15px 0px;
                    text-align: center;
                    &.active{
                        background-color: $primary;
                        color: $white;
                    }
                }
                &.active
                &:hover {
                    a {
                        // background-color: $primary;
                        // color: $white;
                        cursor: pointer;
                        transition: 0.3s;


                        svg {
                            margin-right: 5px;
                        }
                    }
                }
                &:hover {
                    >ul {
                        transition: 0.3s;
                        opacity: 1;
                        top: 100%;
                        visibility: visible;
                        li{
                            >ul{
                                top: 0;
                                left: 240px;
                            }
                            // a{
                            //     background-color: transparent;
                            //     color: $black;
                            // }
                            // &:hover{
                            //     a{
                            //         background-color: $primary;
                            //         color: $white;
                            //     }
                            // }
                        }
                    }
                }
                ul{
                    position: absolute;
                    display: initial;
                    background: $white;
                    min-width: 240px;
                    opacity: 0;
                    transition: 0.3s;
                    visibility: hidden;
                    z-index: 2;
                    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.02);
                    border: 1px solid rgba(0, 0, 0, 0.09);
                    border-radius: 3px;
                    li {
                        float: none;
                        a {
                            padding: 10px 20px;
                            text-align: left;
                        }
                    }
                    // padding: 20px;
                }
            }
        }
        // @include media-breakpoint-down(sm) {
        //     display: none;
        // }

        // ul {
        //     display: flex;
            // margin: 0;
            // padding: 0;
            // list-style: none;

        //     li {
        //         float: left;

        //         a {
        //             color: $black;
        //             transition: 0.1s;
        //             font-size: 16px;
        //             text-decoration: none;
        //             display: block;
        //             text-align: left;
        //             font-weight: 200;
        //             padding: 23px 0;
        //         }

                // &.active,
                // &:hover {
                //     a {
                //         color: $primary;

                //         cursor: pointer;
                //         transition: 0.3s;


                //         svg {
                //             margin-right: 5px;
                //         }
                //     }
                // }
        //     }
        // }

        // &.menu {

            // transition: 0.3s;
            // position: relative;
            // opacity: 1;

        //     li {
        //         position: relative;

        //         a {
        //             // border-top: 5px solid transparent;
        //             text-align: center;
        //             margin: 0 7px;
        //             cursor: pointer;

        //             span {
        //                 max-width: 200px;
        //             }

        //             svg {
        //                 margin: 0 5px;

        //                 &.fa-sort-down {
        //                     position: relative;
        //                     transform: translateX(0px) translateY(-2px);
        //                 }
        //             }

        //             // &:hover {
        //             //     border-top-color: $primary;
        //             // }
        //         }

        //         // &.parent {
        //         //     &:hover {
        //         //         .sub-menu {
        //         //             opacity: 1;
        //         //             transition: 0.3s;
        //         //             visibility: visible;
        //         //             top: 100%;
        //         //             &.parent {
        //         //                 ul{
        //         //                     display: none;
        //         //                 }
        //         //             }
        //         //         }
        //         //     }
        //         // }

        //         &:first-child {
        //             a {
        //                 margin-left: 0px;
        //             }
        //         }

        //         // &:last-child {
        //         //     &.parent {
        //         //         .sub-menu {
        //         //             right: 0;
        //         //         }
        //         //     }
        //         // }

        //         .sub-menu {
                    // position: absolute;
                    // display: initial;
                    // background: $white;
                    // min-width: 240px;
                    // opacity: 0;
                    // transition: 0.3s;
                    // visibility: hidden;
                    // padding-top: 10px;
                    // padding-bottom: 10px;
                    // z-index: 2;
                    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.51);

        //             li {
        //                 float: none;
        //                 padding-left: 10px;
        //                 padding-right: 10px;

        //                 a {
        //                     background-color: none;
        //                     font-size: 14px;
        //                     justify-content: left;
        //                     text-align: left;
        //                     padding: 7px 15px;
        //                     border-top: 0px;
        //                     color: $black;
        //                     margin: 0;

        //                     &:hover {
        //                         color: $primary;
        //                     }
        //                 }

        //                 &+li {
        //                     border: none;
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    &.support {
        .support-icon {
            float: left;

            svg {
                width: 75px;
                height: 75px;
            }
        }

        .support-content {
            margin-left: 95px;

            span {
                a {
                    color: $black;
                }
            }
        }
    }

    &.socials {
        text-align: right;

        a {
            display: inline-flex;
            background-color: $primary;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            font-size: 17px;
            color: $white;
            align-items: center;
            justify-content: center;
            margin: 0 3px;

            &.facebook {
                background-color: #3b5998;
            }

            &.twitter {
                background-color: #1da1f2;
            }

            &.instagram {
                background-color: #9F348F;
            }

            &.you-tube {
                background-color: #cd201f;
            }
        }
    }

    &.useful-links {
        ul {
            margin: 0;
            padding: 0;

            li {
                a {
                    color: $black;

                    svg {
                        margin-right: 3px;
                    }

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    &.page-title {
        h2 {
            font-size: 30px;
            color: $white;
            font-weight: 700;
        }

        .breadcrumb {
            background-color: transparent;
            margin-bottom: 0px;
            padding: 0px;

            li {
                color: $white;

                a {
                    color: $white;
                }

                &.active {
                    svg {
                        margin-right: 10px;
                    }
                }

                &+li {
                    margin-left: 10px;
                }
            }
        }
    }

    &.toggle-block {
        text-align: right;
        margin-left: 0px;
        padding-left: 0px;

        .toggle-menu {

            display: block;
            float: left;

            svg {
                width: 100%;
                height: auto;
            }

        }
    }

    &.mobile-menu {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                a {
                    font-size: 20px;
                    display: block;
                    text-align: center;
                    padding: 0 10px;
                    color: $gray-800;
                    font-weight: 400;

                    &:hover,
                    &:focus {
                        color: $primary;
                    }

                    svg {
                        display: none;
                    }
                }

                ul {
                    li {
                        a {
                            font-size: 14px;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }

    &.brands-slider{
        .brands-slider-wrapper{
            margin: 0 -10px;
            .brand-item{
                width: 16.66%;
                float: left;
                text-align: center;
                .brand-item-inner{
                    background-color: $gray-200;
                    margin: 10px;
                    img{
                        max-width: 100%;
                    }
                }
                a {
                    display: block;

                    &:hover {
                        text-decoration: none;

                        .brand-item-inner {
                            box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
                        }
                    }
                }
            }
        }
    }

    &.product-categories{
        .product-categories-wrapper{
            margin: 0 -10px;
            .category-item {
                width: 16.66%;
                float: left;
                @include media-breakpoint-down(md) {
                    width: 25%;
                }
                @include media-breakpoint-down(sm) {
                    width: 50%;
                }
                text-align: center;
                a{
                    display: block;
                    &:hover{
                        text-decoration: none;
                        .category-item-inner{
                            box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
                        }
                    }
                }
                .category-item-inner {
                    background-color: $gray-200;
                    margin: 10px;
                    padding: 20px;

                    img {
                        max-width: 30%;
                    }

                    svg{
                        font-size: 100px;
                        color: $gray-600;
                    }

                    h4{
                        padding-top: 10px;
                        margin-bottom: 0px;
                        font-size: 15px;
                        color: $gray-900;
                    }
                }
            }
        }
    }

    &.copyright {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    &.developed-by {
        @include media-breakpoint-down(sm) {
            margin-top: 0px;
            padding-top: 0px;
        }
    }

    &.products {
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 7px;

        ul{
            &.nav{
                &.nav-tabs{
                    li{
                        &.nav-item{
                            font-size: 20px;
                            a{
                                &.nav-link{
                                    @include media-breakpoint-down(sm) {
                                        font-size: 13px;
                                    }
                                    &.active{
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .tab-pane{
            position: relative;
            min-height: 150px;
        }

        .product {
            margin-top: 30px;

            .inner {
                background: $white;
                padding: 20px;
                // margin: 5px;
                text-align: center;
                position: relative;
                border: 1px solid #d2d2d2;

                a {
                    text-decoration: none;
                }

                .image {
                    padding-bottom: 10px;

                    img {
                        max-width: 95%;
                        max-height: 220px;
                        width: auto;
                        height: auto;
                    }

                    svg {
                        width: 50px;
                        color: $gray-200;
                        font-size: 100px;
                        margin: 0 auto;
                    }
                }

                h3 {
                    font-size: 16px;
                    font-weight: 300;
                    color: $gray-900;
                }

                h4 {
                    color: $primary;
                    font-weight: 600;
                    margin-bottom: 0px;
                    font-size: 20px;
                    line-height: 20px;

                    strike {
                        color: $gray-500;
                        font-size: 16px;
                    }
                }

                .btn {
                    &.btn-primary {
                        margin-top: 10px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-shadow: none;
                        border-radius: 15px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }

                p {
                    margin-bottom: 0px;
                }

                .detail-text {
                    background: $primary;
                    color: $white;
                    display: inline-block;
                    padding: 5px 10px;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 18px;
                    text-transform: uppercase;
                    line-height: 25px;
                    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
                    margin-top: 10px;
                }
            }

            &:hover {
                .inner {
                    // border-color: $primary;
                    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                    // box-shadow: 0 0 50px 0 rgba(85, 102, 128, 0.17);
                }
            }
        }
    }

    &.product-details {
        h1 {
            font-size: 27px;
            font-weight: 600;
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid $gray-200;
        }

        h2 {
            color: $primary;
            font-size: 40px;
            font-weight: 400;
        }
    }
    &.image-upload {
        .image-upload-inner {
            width: 100%;
            max-height: 150px;
            margin: 0 auto 15px;
            overflow: hidden;
            border-radius: 4px;
            text-align: center;
            position: relative;
            height: 150px;
            background: $gray-300;

            a {
                display: block;
                height: 150px;
                padding: 5px;
                align-items: center;
                display: flex;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    margin: 0 auto;
                    display: table;
                }
            }

            .image-icon {
                font-size: 100px;
                color: $gray-400;
                position: absolute;
                top: 25px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
            }

            .image-remove {
                position: absolute;
                z-index: 1;
                left: 50%;
                margin-left: -42.5px;
                top: 5px;
                width: 85px;
                height: 35px;

                .btn {
                    background: $danger;
                    width: 85px;
                    height: 35px;
                    border-radius: 5px;
                    color: $white;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    border: 0;
                    padding: 0;
                    font-size: 17px;
                }

                &:hover {
                    cursor: pointer;

                    .btn {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

#messages{
    .alert{
        padding: 0;
        &.alert-dismissible{
            padding: 0px;
            .close{
                top: 7px;
                font-size: 40px;
            }
        }
    }
}

#heading {
    background-color: $primary;
    background-size: cover;
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
    }
}

header,
#navigation {
    border-bottom: 1px solid $gray-200;
}

footer {
    #bottom {
        background-color: $gray-300;
    }
}
